import styled from "styled-components";
import Utils from "../../Utils";

const EsActionsContainerStyle = styled.div`

    padding: 14px 0;
    display: flex;
    align-items: center;
    gap: 14px;
    
`

export const EsActionsAlignment = {
    LEFT: "flex-start",
    CENTER: "center",
    RIGHT: "flex-end",
    SPACE: "space-between",
}

export const EsActionsContainer = function ({children, alignment, style}) {

    const getStyle = () => {
        if (Utils.isEmpty(style)) {
            return {
                justifyContent: alignment || EsActionsAlignment.LEFT
            }
        }
        style["justifyContent"] = alignment || EsActionsAlignment.LEFT;
        return style;
    }

    return (
        <EsActionsContainerStyle style={getStyle()}>
            {children}
        </EsActionsContainerStyle>
    )
}