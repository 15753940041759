import styled from 'styled-components';
import { Layout } from 'antd';
import { Logo } from '../../Components/Logo/Logo';
import Config from '../../Config/Config';
import { MainMenuItem } from './MainMenuItem';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import Session from '../../Config/Session';
import { NotApprovedMainMenuItem } from './NotApprovedMainMenuItem';
import { ClientRequestsMenuItem } from './ClientRequestsMenuItem';
import { AdsRequestsMenuItem } from './AdsRequestsMenuItem';
import packageJson from '../../../package.json';

const MainMenuStyle = styled.div`
	.ant-layout-sider {
		overflow: auto;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: var(--color-dark-background) !important;

		.version {
			color: #ffffffcc;
			bottom: 0;
			position: absolute;
			padding: 8px 20px;
		}

		.logoWrapper {
			height: 67px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			.logo {
				width: 40px;
				height: 40px;

				svg {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
`;

const MainMenuItems3 = [
	{
		link: '/articles',
		label: 'Новости'
	},
	{
		link: '/logout',
		label: 'Выход',
		onClick: () => {
			Config.setToken(null);
		}
	}
];

const MainMenuItems10 = [];

export const MainMenu = function () {
	const MainMenuItems = [
		{
			link: '/',
			label: 'Главная',
			isVisible: Session.getLevel() !== 3 && Session.getLevel() !== 10 && Session.getLevel() !== 4
		},
		{
			link: '/articles',
			label: Session.getLevel() === 4 ? 'Мои публикации' : 'Новости',
			isVisible: Session.getLevel() !== 10
		},
		{
			label: 'Задать вопрос',
			isVisible: Session.getLevel() === 4,
			onClick: () => window.open('https://t.me/rrsupbot', '_blank')
		},
		{
			link: '/toApprove',
			label: 'Модерация',
			isVisible: Session.getLevel() !== 3 && Session.getLevel() !== 4 && Session.getLevel() !== 10
		},
		{
			link: '/advertisement',
			//advertisement
			label: 'РР Реклама',
			isVisible: Session.getLevel() === 99 || Session.getLevel() === 10
		},
		{
			link: '/requests',
			label: 'Заявки',
			isVisible: Session.getLevel() === 99 || Session.getLevel() === 10
		},
		{
			link: '/clients',
			label: 'Клиенты РР Пиар',
			isVisible: Session.getLevel() === 99 || Session.getLevel() === 10
		},
		{
			link: '/settings',
			label: 'Настройки',
			isVisible: Session.getLevel() !== 3 && Session.getLevel() !== 4 && Session.getLevel() !== 10
		},
		{
			link: '/logout',
			label: 'Выход',
			isVisible: true,
			onClick: () => {
				Config.setToken(null);
			}
		}
	];

	const getItems = () => {
		return _.where(MainMenuItems, { isVisible: true });
	};

	return (
		<MainMenuStyle>
			<Layout.Sider>
				<div className="logoWrapper">
					<Logo />
				</div>
				{_.map(getItems(), (item, idx) => {
					if (_.has(item, 'onClick')) {
						return <MainMenuItem label={item.label} onClick={item.onClick} key={idx} />;
					}
					if (item.link === '/toApprove') {
						return (
							<Link to={item.link} key={idx}>
								<NotApprovedMainMenuItem label={item.label} />
							</Link>
						);
					}
					if (item.link === '/advertisement') {
						return (
							<Link to={item.link} key={idx}>
								<ClientRequestsMenuItem label={item.label} />
							</Link>
						);
					}
					if (item.link === '/requests') {
						return (
							<Link to={item.link} key={idx}>
								<AdsRequestsMenuItem label={item.label} />
							</Link>
						);
					}
					return (
						<Link to={item.link} key={idx}>
							<MainMenuItem label={item.label} />
						</Link>
					);
				})}
				<span className="version">v.{packageJson['version']}</span>
			</Layout.Sider>
		</MainMenuStyle>
	);
};
