import styled from "styled-components";

const MainMenuItemStyle = styled.div`

    color: #ffffffcc;
    padding: 8px 20px;
    cursor: pointer;
    transition: all 0.2s;
    
    &:hover {
        background-color: var(--color-background);
        color: var(--color-text);
    }
    
`

export const MainMenuItem = function ({label, onClick}) {

    return (
        <MainMenuItemStyle onClick={onClick}>
            {label}
        </MainMenuItemStyle>
    )
}