import _ from 'underscore';

class ObserverManager {
	constructor() {
		this.observers = [];
	}

	addObserver(observer) {
		this.observers.push(observer);
	}

	removeObserver(observer) {
		this.observers = this.observers.filter((obs) => obs !== observer);
	}

	update() {
		_.each(this.observers, (observer) => {
			if (_.isFunction(observer)) {
				observer();
			}
		});
	}
}

export const publishWatcher = new ObserverManager();
