import { App } from 'antd';
import React, { useEffect } from 'react';
import Config from './Config';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from 'react-router';
import Page404 from '../Pages/404/Page404';
import MainLayout from '../Pages/Layout/MainLayout';
import Session from './Session';

const MainPage = React.lazy(() => import('../Pages/MainPage/MainPage'));
const SimpleArticleEdit = React.lazy(() => import('../Pages/SimpleArticleEdit/SimpleArticleEdit'));
const ArticlesPage = React.lazy(() => import('../Pages/ArticlesPage/ArticlesPage'));
const FotApprovedArticlesPage = React.lazy(() =>
	import('../Pages/NotApprovedArticlesPage/NotApprovedArticlesPage')
);
const SettingsPage = React.lazy(() => import('../Pages/SettingsPage/SettingsPage'));
const ClientsPage = React.lazy(() => import('../Pages/ClientsPage/ClientsPage'));
const ClientEditPage = React.lazy(() => import('../Pages/ClientEditPage/ClientEditPage'));
const AdsRequestsPage = React.lazy(() => import('../Pages/AdsRequestsPage/AdsRequestsPage'));
const AdViewerPage = React.lazy(() => import('../Pages/AdViewerPage/AdViewerPage'));

const MainSection = React.lazy(() => import('../Pages/SettingsPage/Sections/Main/MainSettings'));
const AuthorsSection = React.lazy(() =>
	import('../Pages/SettingsPage/Sections/Authors/AuthorsSection')
);
const CategoriesSection = React.lazy(() =>
	import('../Pages/SettingsPage/Sections/Categories/CategoriesSection')
);
const GallerySection = React.lazy(() =>
	import('../Pages/SettingsPage/Sections/Gallery/GallerySection')
);
const PagesSection = React.lazy(() => import('../Pages/SettingsPage/Sections/Pages/PagesSection'));
const PageEditor = React.lazy(() => import('../Pages/SettingsPage/Sections/Pages/PageEditor'));
const SourcesSection = React.lazy(() =>
	import('../Pages/SettingsPage/Sections/Sources/SourcesSection')
);
const UsersSection = React.lazy(() => import('../Pages/SettingsPage/Sections/Users/UsersSection'));

export const AppRoutes = () => {
	const app = App.useApp();

	useEffect(() => {
		Config.setApp(app);
	}, []);

	return (
		<BrowserRouter future={{ v7_startTransition: true }}>
			<Routes>
				<Route element={<MainLayout />} errorElement={<Page404 />}>
					{Session.getLevel() !== 3 && Session.getLevel() !== 4 ? (
						<Route path={'/'} element={<MainPage category={null} />} />
					) : (
						<Route path={'/'} element={<ArticlesPage category={null} />} />
					)}
					<Route path={'/articles'} element={<ArticlesPage category={null} />} />
					{(Session.getLevel() === 99 || Session.getLevel() === 2) && (
						<Route path={'/toApprove'} element={<FotApprovedArticlesPage category={null} />} />
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route path={'/clients'} element={<ClientsPage path={'clients'} />} />
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route path={'/clients/:id'} element={<ClientEditPage path={'clients'} />} />
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route path={'/advertisement'} element={<ClientsPage path={'advertisement'} />} />
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route
							path={'/advertisement/:id'}
							element={<ClientEditPage path={'advertisement'} />}
						/>
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route path={'/requests'} element={<AdsRequestsPage />} />
					)}
					{(Session.getLevel() === 99 || Session.getLevel() === 10) && (
						<Route path={'/requests/:id'} element={<AdViewerPage />} />
					)}
					{Session.getLevel() !== 3 && Session.getLevel() !== 4 && (
						<Route path={'/settings'} element={<SettingsPage category={null} />}>
							<Route path={'/settings/'} element={<GallerySection />} />
							<Route path={'/settings/authors'} element={<AuthorsSection />} />
							<Route path={'/settings/users'} element={<UsersSection />} />
							<Route path={'/settings/pages'} element={<PagesSection />} />
							<Route path={'/settings/pages/:id'} element={<PageEditor />} />
							<Route path={'/settings/categories'} element={<CategoriesSection />} />
							<Route path={'/settings/sources'} element={<SourcesSection />} />
						</Route>
					)}
					<Route path={'*'} element={<SimpleArticleEdit />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};
