import IvenoChecker from "./IvenoChecker";
import IvenoCrypt from "./IvenoCrypt";
import IvenoCommon from "./IvenoCommon";
import IvenoObjects from "./IvenoObjects";
import IvenoRule from "./IvenoRule";
import IvenoDates from "./IvenoDates";
import IvenoFormat from "./IvenoFormat";
import IvenoBus from "./IvenoBus";
import IvenoRepository from "./IvenoRepository";
import IvenoLocale from "./IvenoLocale";
import IvenoUI from "./IvenoUI";

const Iveno = (function () {

    return {
        isDeveloping: () => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
        Checker: IvenoChecker,
        Crypt: IvenoCrypt,
        Common: IvenoCommon,
        Objects: IvenoObjects,
        Rule: IvenoRule,
        Dates: IvenoDates,
        Format: IvenoFormat,
        Bus: IvenoBus,
        Repository: IvenoRepository,
        Locale: IvenoLocale,
        UI: IvenoUI,
    }
})()

export default Iveno;