import styled from "styled-components";

const Page404Style = styled.div`

`

const Page404 = function () {

    return (
        <Page404Style>
404
        </Page404Style>
    )
}

export default Page404;