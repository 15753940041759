const Theme = {
	token: {
		fontFamily: "'Nunito Sans', sans-serif",
		colorPrimary: '#e2635c',
		colorInfo: '#e2635c',
		colorTextBase: '#00254b',
		fontSize: 16,
		wireframe: false,
		colorError: '#e2635c'
		// colorPrimary: '#dd2c34',
	},

	components: {
		// Button: {
		// 	borderRadius: 25
		// 	borderRadiusLG: 25
		// }
		// Input: {
		//     "borderRadius": 25,
		//     "borderRadiusLG": 25,
		//     "borderRadiusSM": 25
		// },
		// Select: {
		//     "borderRadius": 25,
		//     "borderRadiusLG": 25,
		//     "borderRadiusSM": 25
		// },
		// DatePicker: {
		//     "borderRadius": 25,
		//     "borderRadiusLG": 25,
		//     "borderRadiusSM": 25
		// },
	}
};

export default Theme;
