import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const BannerStyle = styled.div`
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: #f8d643;
	padding: 14px;
	z-index: 9999;
	text-align: center;

	span {
		cursor: pointer;
		position: absolute;
		right: 5px;
		top: 5px;
		line-height: 1rem;
		padding: 3px 5px;
	}

	span:hover {
		opacity: 0.8;
	}
`;

export const TemporaryBanner = () => {
	const [showBanner, setShowBanner] = useState(false);
	const [daysLeft, setDaysLeft] = useState(0);

	const testEndDate = new Date('2024-05-17T23:59:00');

	useEffect(() => {
		const now = new Date();
		const nextDay = new Date(localStorage.getItem('lastClosed'));
		nextDay || setShowBanner(true);

		const differenceInDays = Math.floor((testEndDate - now) / (1000 * 60 * 60 * 24));

		if (differenceInDays <= 0) return setShowBanner(false);

		setDaysLeft(differenceInDays);
		if (now >= nextDay) {
			localStorage.removeItem('lastClosed');
			setShowBanner(true);
		}
	}, []);

	const closeBanner = () => {
		const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

		localStorage.setItem('lastClosed', nextDay.toISOString());
		setShowBanner(false);
	};

	if (showBanner)
		return (
			<BannerStyle>
				Вы пользуетесь системой в тестовом режиме. Осталось {daysLeft} дней
				<span onClick={closeBanner}>✖</span>
			</BannerStyle>
		);
};
