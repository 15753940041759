import styled from "styled-components";
import {useEffect, useState} from "react";
import Net from "../../Libs/Net";
import {Badge} from "antd";

const MainMenuItemStyle = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffffcc;
    padding: 8px 20px;
    cursor: pointer;
    transition: all 0.2s;
    
    &:hover {
        background-color: var(--color-background);
        color: var(--color-text);
    }
    
`

export const ClientRequestsMenuItem = function ({label, onClick}) {

    const [count, setCount] = useState(0);

    useEffect(() => {
        updateCount();
        setInterval(updateCount, 3000);
    }, []);

    const updateCount = () => {
        Net.post("getClientRequestsCount", {})
            .then(res => {
                setCount(res);
            })
            .catch(() => {});
    }

    return (
        <MainMenuItemStyle onClick={onClick}>
            {label} <Badge count={count} />
        </MainMenuItemStyle>
    )
}