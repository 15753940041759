import styled from 'styled-components';
import { useEffect } from 'react';
import { IoPlayCircleOutline, IoStopCircleOutline } from 'react-icons/io5';
import Iveno from '../../../Libs/Iveno/Iveno';
import Session from '../../../Config/Session';
import WorkDay from '../../../Config/WorkDay';
import { useUpdate } from 'ahooks';
import { Spin } from 'antd';
import { WorkSessionCounter } from './WorkSessionCounter';

const WorkDayButtonStyle = styled.div`
	display: flex;
	align-items: center;

	.actionButton {
		height: 40px;
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 4px;
		transition: all 0.2s ease-in-out;
		gap: 4px;

		.icon {
			font-size: 32px;
		}
	}

	.actionButton:hover {
		color: var(--color-background);
	}
`;

export const WorkDayButton = function () {
	const isVisible = Session.getLevel() === 3;
	const update = useUpdate();

	useEffect(() => {
		const scopeID = Iveno.Crypt.random();
		Iveno.Bus.on(
			WorkDay.updateEventID,
			() => {
				update();
			},
			scopeID
		);
		return () => {
			Iveno.Bus.offScope(scopeID);
		};
	}, []);

	const renderStartButton = () => {
		return (
			<div className="actionButton" onClick={() => WorkDay.start()}>
				<IoPlayCircleOutline className="icon" />
				Начать смену
			</div>
		);
	};

	const renderStopButton = () => {
		return (
			<>
				{isVisible && <WorkSessionCounter />}
				<div className="actionButton" onClick={() => WorkDay.end()}>
					<IoStopCircleOutline className="icon" />
					Завершить смену
				</div>
			</>
		);
	};

	return (
		<Spin spinning={WorkDay.isLoading()}>
			<WorkDayButtonStyle>
				{Iveno.Checker.isEmpty(WorkDay.getStartTime())
					? renderStartButton()
					: renderStopButton()}
			</WorkDayButtonStyle>
		</Spin>
	);
};
