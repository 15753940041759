import _ from "underscore";
import {useState} from "react";
import {Select} from "antd";
import Net from "../../Libs/Net";
import Iveno from "../../Libs/Iveno/Iveno";
import {EsLinkSelect} from "../../Libs/EsLib/EsLinkSelect/EsLinkSelect";

export const Tagger = function ({tags, onChange, disabled, linked}) {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const fetch = (val) => {
        setLoading(true);
        Net.post("suggestMediaTags", {str: val})
            .then(response => {
                setItems(_.map(response, item => {
                    return {
                        value: item?.toLowerCase(),
                        label: item?.toLowerCase(),
                    }
                }))
                setLoading(false);
            })
    }

    const handleChange = (newTags) => {
        if (_.isFunction(onChange)) {
            if (!_.isArray(newTags)) {
                newTags = [];
            }
            let _items = [];
            _.each(newTags, t => {
                if (t.length > 1) {
                    _items.push(t.toLowerCase())
                }
            })
            onChange(_.uniq(_.compact(_items)));
        }
    }

    if (linked) {
        return (
            <EsLinkSelect mode="tags"
                          disabled={disabled}
                          loading={loading}
                          value={tags}
                          onChange={handleChange}
                          onSearch={fetch}
                          options={items}
                          style={{width: "100%"}}
                          placeholder="Укажите ключевые слова"
                          open={open && !Iveno.Checker.isEmpty(items)}
                          onDropdownVisibleChange={setOpen}
                          onSelect={() => setOpen(false)}
            />
        )
    }

    return (
        <Select mode="tags"
                disabled={disabled}
                loading={loading}
                value={tags}
                onChange={handleChange}
                onSearch={fetch}
                options={items}
                style={{width: "100%"}}
                placeholder="Укажите ключевые слова"
                open={open && !Iveno.Checker.isEmpty(items)}
                onDropdownVisibleChange={setOpen}
                onSelect={() => setOpen(false)}
        />
    )
}