import {useEffect, useState} from 'react';
import {Badge, Tooltip} from 'antd';
import Net from '../../../Libs/Net';
import {publishWatcher} from '../../../Libs/ObserverManager ';

export const WorkSessionCounter = function () {
    const [update, setUpdate] = useState(true);
    const [count, setCount] = useState(30);

    const toggleUpState = () => setUpdate((prev) => !prev);

    useEffect(() => {
        publishWatcher.addObserver(toggleUpState);
        load();
        return () => publishWatcher.removeObserver(toggleUpState);
    }, [update]);

    const load = () => {
        Net.post('getModeratorSessionPosts', {})
            .then((res) => {
                typeof res === 'number' && setCount(30 - res);
            })
            .catch(() => {
            });
    };

    const genBadgeAndTooltipProps = (count) => {
        const tooltipTitle = count <= 0 ? 'Выполнено' : 'Не выполнено';
        const badgeText = count === 0 ? '✔' : Math.abs(count);
        const badgeStyle = {
            userSelect: 'none',
            background: count <= 0 && '#33b133',
            pointerEvents: 'none',
            height: '2rem',
            width: '2rem',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        };

        return {
            tooltipTitle,
            badgeText,
            badgeStyle
        };
    };

    const {tooltipTitle, badgeText, badgeStyle} =
        genBadgeAndTooltipProps(count);
    return (
        <Tooltip title={tooltipTitle}>
            <Badge count={badgeText} style={badgeStyle}/>
        </Tooltip>
    );
};