import styled from 'styled-components';
import { EsImage } from '../../../Libs/EsLib/EsImage/EsImage';
import { Overlay } from './Overlay';
import Iveno from '../../../Libs/Iveno/Iveno';

const MainImageEditorStyle = styled.div`
	position: relative;
	aspect-ratio: 900 / 440;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	overflow: hidden;
	object-fit: cover;
	width: auto;
	height: auto;

	.imageAuthorLabel {
		position: absolute;
		bottom: 10px;
		right: 12px;
		z-index: 100;
		background-color: var(--color-dark-background);
		padding: 4px 10px;
		border-radius: 20px;
		font-size: 12px;
		color: #ffffff;
	}
`;

export const ImageEditor = function ({ controller }) {
	return (
		<MainImageEditorStyle>
			<EsImage width="100%" src={controller.getData('image')} type="original" withPreview={false} />
			{!Iveno.Checker.isEmpty(controller.getData('imageAuthorName')) && (
				<div className="imageAuthorLabel">{controller.getData('imageAuthorName')}</div>
			)}
			{!controller.readonly() && <Overlay controller={controller} />}
		</MainImageEditorStyle>
	);
};
