import Net from "../Libs/Net";
import Iveno from "../Libs/Iveno/Iveno";
import Config from "./Config";

const workDayUpdateEvent = "9fb17a11-da5c-45fd-a6d7-371e08ee4d1c";

const WorkDay = (function () {

    let loading = false;
    let workDayStartTime = null;
    let loaded = false;

    const loadWorkDay = () => {
        if (!Config.isAuth()) {
            return;
        }
        loading = true;
        update();
        Net.post("isWorkDay", {})
            .then((res) => {
                if (!Iveno.Checker.isEmpty(res)) {
                    workDayStartTime = res;
                } else {
                    workDayStartTime = null;
                }
                loading = false;
                loaded = true;
                update();
            })
            .catch(() => {
                loading = false;
                update();
            })
    }

    const startWorkDay = () => {
        loading = true;
        update();
        Net.post("startWorkDay", {})
            .then(res => {
                if (!Iveno.Checker.isEmpty(res)) {
                    workDayStartTime = res;
                } else {
                    workDayStartTime = null;
                }
                loading = false;
                loaded = true;
                update();
            })
            .catch(() => {
                loading = false;
                update();
            })
    }

    const endWorkDay = () => {
        loading = true;
        update();
        Net.post("endWorkDay", {})
            .then(() => {
                workDayStartTime = null;
                loading = false;
                loaded = true;
                update();
            })
            .catch(() => {
                loading = false;
                update();
            })
    }

    const update = () => {
        Iveno.Bus.emit(workDayUpdateEvent);
    }

    loadWorkDay();

    return {
        updateEventID: workDayUpdateEvent,
        setStartTime: (startTime) => {
            workDayStartTime = startTime;
        },
        getStartTime: () => {
            return workDayStartTime;
        },
        isLoaded: () => {
            return loaded;
        },
        isLoading: () => {
            return loading;
        },
        start: () => {
            startWorkDay();
        },
        end: () => {
            endWorkDay();
        }
    }
})()

export default WorkDay;