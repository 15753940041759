import styled from 'styled-components';
import { ImageGalleryDialog } from '../../SimpleArticleEdit/ImageGalleryDialog/ImageGalleryDialog';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { Upload } from 'antd';

const OverlayStyle = styled.div`
	position: absolute;
	box-shadow: inset 0 0 300px 150px #666;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.2s ease;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	color: #ffffff;
	line-height: 1;
	user-select: none;

	&:hover {
		opacity: 1;
	}

	.imageActionButtons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		.ant-upload-wrapper {
			line-height: unset;
		}
	}

	.imageActionButton {
		width: 150px;
		max-width: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding: 14px 20px;
		border-radius: 14px;
		overflow: hidden;
		cursor: pointer;
		transition: all 0.2s ease;
		color: #ffffff;

		.icon {
			font-size: 32px;
		}
	}

	.imageActionButton:hover {
		background-color: #ffffff22;
	}

	.linkButtonWrapper {
		width: 490px;

		.ant-space-compact {
			width: 100%;
		}

		input {
			border: 1px solid #ffffff;
			color: #ffffff;
		}

		input::-ms-input-placeholder {
			color: var(--color-dark-background);
		}

		input::placeholder {
			color: var(--color-dark-background);
		}
	}

	.authorWrapper {
		position: absolute;
		bottom: 10px;
		left: 10px;
		.ant-input {
			width: 300px;
		}
	}
`;

export const Overlay = function ({ controller }) {
	const onUpload = (data) => {
		controller.setData('image', data.file);
	};

	return (
		<OverlayStyle>
			<div className="imageActionButtons">
				<Upload
					showUploadList={false}
					beforeUpload={() => false}
					multiple={false}
					className="uploadControl"
					accept="image/*"
					onChange={onUpload}
				>
					<div className="imageActionButton">
						<IoCloudUploadOutline className="icon" />
						Загрузить
					</div>
				</Upload>
			</div>
			<div className="linkButtonWrapper"></div>

			<ImageGalleryDialog
				callback={(data) => controller.setData('image', data)}
				controller={controller}
			/>
		</OverlayStyle>
	);
};
