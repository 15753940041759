import _ from "underscore";
import Utils from "./Utils";

const Rule = (function () {
	return {
		uuid: (val) => Utils.isUUID(val),
		numeric: (val) => _.isNumber(val),
		empty: (val) => Utils.isEmpty(val),
		notEmpty: (val) => !Utils.isEmpty(val),
		minLength: (len) => {
			let f = function (val) {
				return _.size(val) >= len;
			}
			f.bind(len);
			return f;
		},
		isEqual: (v2) => {
			let f = function (val) {
				return val === v2;
			}
			f.bind(v2);
			return f;
		},
		isEmail: (val) => Utils.isEmail(val)
	}
})()

export default Rule;