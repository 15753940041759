import axios from "axios";
import _ from "underscore";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Config from "../Config/Config";
import Iveno from "./Iveno/Iveno";

const Net = (function (host, port, version) {

    let visitorID;

    const fpPromise = FingerprintJS.load()
    fpPromise
        .then(fp => fp.get())
        .then(result => {
            visitorID = result["visitorId"]
        })

    let instance = axios.create({
        baseURL: _.compact([host, port]).join(":") + "/"
    })

    const _post = (method, params, progressCallback) => {
        return new Promise((resolve, reject) => {
            let files = Iveno.Objects.extractFilesFromObject(params);
            let headers = {}
            if (Config.isAuth()) {
                headers["Authorization"] = Config.getToken();
            }
            if (!Iveno.Checker.isEmpty(files)) {
                headers["Content-Type"] = "multipart/form-data";
            }
            instance.post(
                "/",
                {method: method, version: version, payload: params, visitorID: visitorID},
                {
                    headers: headers,
                    onUploadProgress: function (progressEvent) {
                        if (_.isFunction(progressCallback)) {
                            progressCallback(progressEvent)
                        }
                    },
                }
            )
                .then(response => {
                    if ((response.status === 200 && response.data.status === 'success')) {
                        resolve(response.data.response);
                    } else {
                        reject(response.data.response);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    return {
        post: (method, params, progressCallback) => {
            return _post(method, params, progressCallback);
        }
    }

})(
    "https://api.gosrf.ru",
    443,
    99
)

export default Net;