import _ from "underscore";
import {validate} from "uuid";

const Utils = (function () {

    const _getNumberAsStringSuffix = (val, singular, plural, many) => {
        let _singular = singular;
        let _plural = plural;
        let _many = many;
        if (_.isArray(singular)) {
            _singular = singular[0];
            _plural = singular[1];
            _many = singular[2];
        }
        let n = val * 1;
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) return _many;
        if (n1 > 1 && n1 < 5) return _plural;
        if (n1 === 1) return _singular;
        return _many;
    }

    return {
        isDev: () => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
        isEmpty: (val) => {
            if (val === undefined) {
                return true;
            }
            if (val === null) {
                return true;
            }
            if (_.isNaN(val)) {
                return true;
            }
            if (_.isString(val)) {
                if (val.trim() === "") {
                    return true;
                }
            }
            if (_.isNumber(val)) {
                if (val === 0) {
                    return true;
                }
            }
            if (_.isArray(val)) {
                if (_.isEmpty(val)) {
                    return true;
                }
            }
            return false;
        },
        inArray: (val, arr) => {
            return _.indexOf(arr, val) !== -1;
        },
        randomIntFromInterval: (min, max) => {
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        clone: (obj) => JSON.parse(JSON.stringify(obj)),
        toNumberWithSuffix: (val, singular, plural, many, onlySuffix) => {
            if (onlySuffix) {
                return _getNumberAsStringSuffix(val, singular, plural, many)
            }
            return [val, _getNumberAsStringSuffix(val, singular, plural, many)].join(" ");
        },
        isEmail: (val) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(val).toLowerCase());
        },
        isUUID: (val) => {
            return validate(val);
        },
    }

})()

export default Utils;