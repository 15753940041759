import styled from 'styled-components';
import { EsTextEditUploadAdapter } from '../../../Libs/EsLib/EsTextEdit/EsTextEditUploadAdapter';
import { useRef } from 'react';
import Iveno from '../../../Libs/Iveno/Iveno';
import Editor from 'ckeditor5-custom-build';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import _ from 'underscore';

const EsTextEditStyle = styled.div`
	position: relative;

	.ck-powered-by-balloon {
		display: none !important;
	}

	img {
		max-width: 100%;
		width: auto;
		height: auto;
	}

	.ck-editor__editable {
		min-height: 400px;
	}

	.ck-editor__main {
		overflow-y: auto;
	}

	.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
		.ck-focused {
			border: none;
			box-shadow: none;
		}
	}
`;

export const TextEdit = function ({ value, onChange, disabled }) {
	const editor = useRef();

	const handleEditorReady = (e) => {
		if (e) {
			editor.current = e;
			editor.current.plugins.get('FileRepository').createUploadAdapter = (loader) => {
				return new EsTextEditUploadAdapter(loader);
			};
		}
	};

	const handleEditorChange = (event, editor) => {
		let data = editor.getData();
		if (_.isFunction(onChange)) {
			if (Iveno.Checker.isEmpty(data)) {
				onChange(null);
			} else {
				onChange(data);
			}
		}
	};

	const config = {
		mediaEmbed: { previewsInData: true },
		toolbar: {
			items: ['bold', 'italic', 'imageUpload', 'mediaEmbed', 'link', 'bulletedList']
		}
	};

	return (
		<EsTextEditStyle>
			<CKEditor
				editor={Editor}
				config={config}
				onReady={handleEditorReady}
				data={Iveno.Checker.isEmpty(value) ? '' : value}
				onChange={handleEditorChange}
				disabled={disabled}
			/>
		</EsTextEditStyle>
	);
};
