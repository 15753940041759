import styled from "styled-components";
import old_rr from "../../../Assets//old_rr.png";
import unsplash from "../../../Assets/unsplash.png";

const ModeSelectorStyle = styled.div`
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    user-select: none;
    
    .mode {
        width: 200px;
        max-width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 20px;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        img {
            max-height: 40px;
            object-fit: contain;
        }
        &:hover {
            background-color: var(--color-background);
            border: 1px solid var(--color-gray)
        }
        &.active {
            background-color: var(--color-background);
        }
    }
`

const modes = [
    {
        mode: "gosrf",
        icon: old_rr,
    },
    {
        mode: "unsplash",
        icon: unsplash,
    }

]

export const ModeSelector = function ({mode, onChange}) {

    return (
        <ModeSelectorStyle>
            {modes.map((m, i) => (
                <div key={i} className={`mode ${mode === m.mode ? "active" : ""}`} onClick={() => onChange(m.mode)}>
                    <img src={m.icon} alt={m.name}/>
                </div>
            ))}
        </ModeSelectorStyle>
    )
}