export function isValidEmbedUrlStructure(params) {
	const url = window.location.pathname;
	const parts = url.split('/');
	if (parts.length < 4 && parts[1].toLowerCase() !== 'embed') {
		return false;
	}
	const hasSecondPart = parts[2]?.length > 0;
	const hasThirdPart = parts[3]?.length > 0;

	if (params && hasSecondPart && hasThirdPart) {
		return { id: parts[2], token: parts[3] };
	}

	return hasSecondPart && hasThirdPart;
}
