import _ from 'underscore';
import { isDayjs } from 'dayjs';
import Utils from './Utils';
import Net from './Net';
import Iveno from './Iveno/Iveno';

export default class Controller {
	structure = {};
	fields = {};
	rules = {};
	errors = {};
	actions = {
		put: null,
		get: null,
		delete: null
	};
	loaded = false;
	loading = false;
	observers = [];

	constructor(getAction, putAction, deleteAction) {
		this.actions.put = putAction;
		this.actions.get = getAction;
		this.actions.delete = deleteAction;
	}

	setStructure(structure) {
		this.structure = Utils.clone(structure);
		this.fields = structure;
	}

	addObserver(observer) {
		this.observers.push(observer);
	}

	updateView() {
		_.each(this.observers, (observer) => {
			if (_.isFunction(observer)) {
				observer();
			}
		});
	}

	setLoading(loading) {
		this.loading = loading;
		this.updateView();
	}

	getData(fieldName, defaultValue, convertDate = true) {
		let fieldPath = fieldName.split('.');
		let r = this.fields;
		_.each(fieldPath, (f) => {
			r = _.propertyOf(r)(f);
		});
		if (Utils.isEmpty(r)) {
			return defaultValue;
		}
		if (convertDate) {
			if (fieldName.toLowerCase().indexOf('date') !== -1) {
				return Iveno.Dates.asObject(r);
			}
		}
		return r;
	}

	setData(fieldName, value) {
		let fieldPath = fieldName.split('.');
		let r = this.fields;
		_.each(fieldPath, (f, idx) => {
			if (idx === _.size(fieldPath) - 1) {
				r[f] = this.normalize(fieldName, value);
			} else {
				r = _.propertyOf(r)(f);
			}
		});
		this.updateView();
	}

	normalize(fieldName, value) {
		if (isDayjs(value)) {
			return value.format('YYYY-MM-DD HH:mm:ssZ');
		}
		return value;
	}

	addRule(fieldName, checkFunction, errorMessage) {
		this.rules[fieldName] = {
			func: checkFunction,
			msg: errorMessage
		};
	}

	clearErrors() {
		this.errors = {};
	}

	clearRules() {
		this.rules = {};
	}

	clear() {
		this.fields = Utils.clone(this.structure);
		this.clearErrors();
		this.updateView();
	}

	checkData() {
		let ruleFields = _.allKeys(this.rules);
		this.errors = {};
		_.each(ruleFields, (rf) => {
			const rule = this.rules[rf];
			if (_.isFunction(rule.func)) {
				if (!rule.func(this.getData(rf, null))) {
					this.errors[rf] = {
						hasFeedback: true,
						validateStatus: 'error',
						help: rule.msg
					};
				} else {
					this.errors[rf] = {
						hasFeedback: false,
						validateStatus: 'success'
					};
				}
			}
		});
		this.updateView();
	}

	getStatus(fieldName) {
		if (_.has(this.errors, fieldName)) {
			return this.errors[fieldName];
		}
		return undefined;
	}

	hasErrors() {
		let e = false;
		_.each(_.allKeys(this.errors), (f) => {
			e = e || this.errors[f].validateStatus === 'error';
		});
		return e;
	}

	put() {
		return new Promise((resolve, reject) => {
			if (_.isString(this.actions.put)) {
				this.loading = true;
				this.updateView();
				Net.post(this.actions.put, Utils.isEmpty(this.fields) ? {} : this.fields)
					.then((res) => {
						this.fields = res;
						this.loading = false;
						this.updateView();
						resolve();
					})
					.catch((res) => {
						this.loading = false;
						this.updateView();
						reject(res);
					});
			} else {
				resolve();
			}
		});
	}

	get(params) {
		return new Promise((resolve, reject) => {
			if (_.isString(this.actions.get)) {
				this.loading = true;
				this.updateView();
				Net.post(this.actions.get, params)
					.then((res) => {
						this.fields = res;
						this.loading = false;
						this.updateView();
						resolve();
					})
					.catch((res) => {
						this.loading = false;
						this.updateView();
						reject(res);
					});
			} else {
				resolve();
			}
		});
	}

	delete(params) {
		return new Promise((resolve, reject) => {
			if (_.isString(this.actions.get)) {
				this.loading = true;
				this.updateView();
				Net.post(this.actions.delete, params)
					.then((res) => {
						this.fields = res;
						this.loading = false;
						this.updateView();
						resolve();
					})
					.catch((res) => {
						this.loading = false;
						this.updateView();
						reject(res);
					});
			} else {
				resolve();
			}
		});
	}

	save() {
		this.savePromise()
			.then(() => {})
			.catch(() => {});
	}

	savePromise(withoutCheck = false, reload = false) {
		let isNew = this.getData('id', null) === null;
		return new Promise((resolve, reject) => {
			if (!withoutCheck) {
				this.checkData();
				if (this.hasErrors()) {
					reject();
					return;
				}
			}
			this.setLoading(true);
			this.put()
				.then(() => {
					if (isNew) {
						if (reload) {
							window.location.reload();
						} else {
							window.location.href = '/' + this.getData('id', null);
						}
					}
					this.setLoading(false);
					resolve();
				})
				.catch((err) => {
					Iveno.UI.errorDialog(err, () => {
						this.setLoading(false);
						reject();
					});
				});
		});
	}
}
