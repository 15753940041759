import styled from "styled-components";
import {Layout} from "antd";

const MainContentStyle = styled.div`
    
    background-color: var(--color-dark-background);
    .ant-layout-content {
        position: relative;
        min-height: calc(100vh - 64px);
        border-radius: 14px;
        background-color: var(--color-background) !important;
        overflow: hidden;
    }
`

export const MainContent = function ({children}) {

    return (
        <MainContentStyle>
            <Layout.Content>
                {children}
            </Layout.Content>
        </MainContentStyle>
    )
}