import React, { useEffect, useRef, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import '../editor.css';

const SendButtonStyle = styled(Button)`
	width: 96px;
	height: 96px;
	border-radius: 28px;
	position: fixed;
	z-index: 99;
	bottom: 3%;
	right: 5%;
	background: #e2635c;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 27px;
		height: 27px;
		color: white;
	}

	&:active {
		border: 3px solid;
	}

	@media (max-width: 600px) {
		right: 8%;
		width: 70px;
		height: 70px;
		border-radius: 24px;
	}

	svg {
		width: 23px;
		height: 23px;
	}
`;

export function SendButton({ onClick, isHolding, setIsHolding, setIsDone }) {
	const [holdDuration, setHoldDuration] = useState(0);
	const [bePublished, setBePublished] = useState(true);

	const elementRef = useRef(null);

	useEffect(() => {
		const element = elementRef.current;
		element.addEventListener('touchstart', handleMouseDown);
		element.addEventListener('touchend', handleMouseUp);

		if (isHolding) {
			interval.start(updateHoldDuration, 100);
			return () => {
				element.removeEventListener('touchstart', handleMouseDown);
				element.removeEventListener('touchend', handleMouseUp);
				interval.clear();
			};
		}
	}, [holdDuration, isHolding]);

	const interval = {
		intervalId: null,
		start(fn, time) {
			this.intervalId = setInterval(fn, time);
		},

		clear() {
			if (this.intervalId !== null) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		}
	};

	const allClear = (isHold = false) => {
		if (bePublished) {
			setIsHolding(isHold);
		}
		setHoldDuration(0);
		interval.clear();
	};

	const handleMouseDown = () => allClear(true);
	const handleMouseUp = () => allClear();

	const updateHoldDuration = () => {
		if (holdDuration >= 1400) {
			setIsDone(true);
			setIsHolding(false);
			if (bePublished) {
				setTimeout(() => {
					onClick();
					allClear();
				}, 1000);
			}
			setBePublished(false);
		}

		if (isHolding) {
			setHoldDuration((prevDuration) => prevDuration + 100);
		}
	};

	return (
		<>
			<SendButtonStyle
				ref={elementRef}
				className={`${isHolding ? 'progress-fill' : ''}`}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseUp}
			>
				<SendOutlined />
			</SendButtonStyle>
		</>
	);
}
