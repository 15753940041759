const IvenoUI = (function () {

	let _app;

	return {
		setApp: (app) => {
			_app = app;
		},
		errorDialog: (err, afterClose) => {
			_app.modal.error({
				centered: true,
				title: "Ошибка",
				content: err,
				afterClose: afterClose,
				okText: "Закрыть"
			});
		},
		messageDialog: (title, str, afterClose) => {
			_app.modal.info({
				centered: true,
				title: title,
				content: str,
				afterClose: afterClose,
				okButtonProps: {type: "primary"}
			});
		},
		confirmDialog: (text, onDelete, deleteText = 'Удалить') => {
			_app.modal.confirm({
				centered: true,
				title: "Подтверждение",
				content: text,
				okText: deleteText || "Удалить",
				okButtonProps: {danger: true},
				cancelButtonProps: {type: "text"},
				onOk: onDelete
			})
		},
		notification: {
			success: (message, description, key) => {
				_app.notification.success({
					message: message,
					description: description,
					key: key
				})
			},
			error: (message, description, key) => {
				_app.notification.error({
					message: message,
					description: description,
					key: key
				})
			},
			info: (message, description, key) => {
				_app.notification.info({
					message: message,
					description: description,
					key: key
				})
			},
			warning: (message, description, key) => {
				_app.notification.warning({
					message: message,
					description: description,
					key: key
				})
			},
		},
	}
})()

export default IvenoUI;