import {sha256} from "js-sha256";
import {v4 as uuid_v4} from 'uuid'
import _ from "underscore";

const IvenoBus = (function () {
	let observers = {};
	let scopes = {};

	return {
		on: (event, observer, scope) => {
			const id = sha256(uuid_v4());
			observers[id] = {
				event: event,
				observer: observer
			};
			if (scope) {
				if (_.isUndefined(scopes[scope])) scopes[scope] = [];
				scopes[scope].push(id);
			}
			return id;
		},
		off: (id) => {
			if (!_.isUndefined(observers[id])) {
				observers = _.omit(observers, id);
			}
		},
		offScope: (scope) => {
			if (!_.isUndefined(scopes[scope])) {
				_.each(scopes[scope], id => {
					IvenoBus.off(id);
				});
				scopes = _.omit(scopes, scope);
			}
		},
		emit: (event, payload) => {
			let targetObservers = _.where(observers, {event: event});
			let obsoleteTargetObservers = [];
			_.each(targetObservers, o => {
				if (_.isFunction(o.observer)) {
					o.observer(payload);
				} else {
					obsoleteTargetObservers.push(o);
				}
			});
			_.each(obsoleteTargetObservers, o => {
				observers = _.without(observers, o);
			})
		}
	}
})()

export default IvenoBus;