import _ from "underscore";

const IvenoObjects = (function () {
	return {
		extractFilesFromObject: (obj) => {
			let files = [];
			const enumAttributes = (obj) => {
				if (_.isObject(obj) && !_.isArray(obj) && !(obj instanceof File)) {
					let keys = _.allKeys(obj);
					_.each(keys, key => {
						if (obj[key] instanceof File) {
							files.push(obj[key]);
						} else {
							enumAttributes(obj[key]);
						}
					})
				}
				if (_.isArray(obj)) {
					_.each(obj, item => {
						if (item instanceof File) {
							files.push(item);
						} else {
							enumAttributes(item);
						}
					})
				}
			}

			_.each(_.allKeys(obj), k => {
				if (obj[k] instanceof File) {
					files.push(obj[k]);
				} else {
					enumAttributes(obj[k]);
				}
			})

			return files;
		}
	}
})()

export default IvenoObjects;