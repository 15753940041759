import IvenoChecker from "./IvenoChecker";

const IvenoCommon = (function () {
    return {
        clone: (obj) => JSON.parse(JSON.stringify(obj)),
        coalesce: (value, defaultValue) => IvenoChecker.isEmpty(value) ? defaultValue : value,
        readFile: (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        getTinName: (resources, countryCode) => {
            if (IvenoChecker.isEmpty(resources[countryCode])) {
                return "TIN";
            }
            return resources[countryCode];
        }
    }
})()

export default IvenoCommon;