import styled from 'styled-components';
import { Outlet } from 'react-router';
import { App, Layout } from 'antd';
import { MainMenu } from './MainMenu';
import { MainHeader } from './MainHeader';
import { MainContent } from './MainContent';
import { useEffect } from 'react';
import Config from '../../Config/Config';
import Iveno from '../../Libs/Iveno/Iveno';
import { TemporaryBanner } from '../../Components/TemporaryBanner/TemporaryBanner';
import Session from '../../Config/Session';

const LayoutStyle = styled.div`
	position: relative;
`;

const MainLayout = function () {
	const app = App.useApp();

	useEffect(() => {
		Config.setApp(app);
		Iveno.UI.setApp(app);
	}, []);

	return (
		<LayoutStyle>
			<Layout hasSider>
				<MainMenu />
				<Layout className="site-layout" style={{ marginLeft: 200 }}>
					{Session.getLevel() === 4 && <TemporaryBanner />}
					<MainHeader />
					<MainContent>
						<Outlet />
					</MainContent>
				</Layout>
			</Layout>
		</LayoutStyle>
	);
};

export default MainLayout;
