import styled from "styled-components";
import _ from "underscore";
import {useEffect, useState} from "react";
import {Tagger} from "../../../Components/Tagger/Tagger";
import Iveno from "../../../Libs/Iveno/Iveno";
import Net from "../../../Libs/Net";
import {Alert, Input, Pagination, Skeleton} from "antd";
import {EsImage} from "../../../Libs/EsLib/EsImage/EsImage";

const GalleryStyle = styled.div`
    flex: 1;
    height: 520px;
    max-height: 520px;
    display: flex;
    flex-direction: column;
    user-select: none;
    border-radius: 8px;

    .searchPanel {
        width: 100%;
        margin-bottom: 8px;
    }

    .imagesGrid {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 4px;
        padding-bottom: 14px;
        overflow-y: auto;

        .ant-skeleton-image {
            width: 100%;
        }

        .ant-image {
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
        }
    }

    .paginationWrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0 10px;
    }
`

export const Gallery = function ({mode, controller, onSelect}) {

    const [tags, setTags] = useState(controller.getData("tags", []));
    const [str, setStr] = useState(controller.getData("title", null));
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        switch (mode) {
            case "unsplash":
                load(1, str);
                break;
            case "gosrf":
                firstLoad();
                break;
            default:
                break;
        }
    }, [mode]);

    useEffect(() => {
        if (Iveno.Checker.isEmpty(tags)) {
            setItems([]);
            setCount(0);
            return;
        }
        load(1, str);
    }, [tags]);

    const load = (page, str) => {
        switch (mode) {
            case "unsplash":
                loadUnsplash(page, str);
                break;
            case "gosrf":
                loadMedia(page);
                break;
            default:
                break;
        }
    }

    const loadMedia = (page) => {
        setLoading(true);
        setCurrentPage(page);
        Net.post("filterMediaByTags", {tags: tags, offset: (page - 1) * limit, limit: limit})
            .then(response => {
                setCount(response["count"]);
                setItems(response["items"]);
                setLoading(false);
                setLoaded(true);
            })
            .catch(() => {
            })
    }

    const loadUnsplash = (page, str) => {
        setLoading(true);
        setCurrentPage(page);
        Net.post("getUnsplashImagesPages", {str: str, page: page, limit: limit})
            .then(response => {
                setCount(response["count"]);
                setItems(response["items"]);
                setLoading(false);
                setLoaded(true);
            })
            .catch(() => {
            });
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Назад</a>;
        }
        if (type === 'next') {
            return <a>Далее</a>;
        }
        return originalElement;
    }

    const renderTotalLabel = () => {
        const n = Iveno.Format.toNumberWithSuffix(count, "изображение", "изображения", "изображений", true)
        return "Всего " + Iveno.Format.number(count) + " " + n;
    }

    const firstLoad = () => {
        if (Iveno.Checker.isEmpty(controller.getData("id", null))) {
            return;
        }
        if (Iveno.Checker.isEmpty(controller.getData("tags", []))) {
            return;
        }
        setLoading(true);
        setLoading(true);
        setCurrentPage(1);
        Net.post("filterMediaByTags", {tags: controller.getData("tags", []), offset: 0, limit: limit})
            .then(response => {
                setCount(response["count"]);
                setItems(response["items"]);
                setLoading(false);
                setLoaded(true);
            })
            .catch(() => {
            })
    }

    const selectImage = (item) => {
        switch (mode) {
            case "unsplash":
                controller.setData("image", item["original"]);
                if (_.has(item, "user")) {
                    controller.setData("imageAuthorName", item["user"]);
                }
                break;
            case "gosrf":
                controller.setData("image", item);
                break;
        }
        if (_.isFunction(onSelect)) {
            onSelect();
        }
    }

    return (
        <GalleryStyle>
            <div className="searchPanel">
                {
                    mode === "unsplash" ?
                        <Input.Search value={str}
                                      onChange={e => setStr(e.target.value)}
                                      placeholder="Поиск по Unsplash"
                                      onSearch={value => load(1, value)}
                                      onPressEnter={() => load(1, str)}
                                      loading={loading}
                        /> : <Tagger tags={tags} onChange={setTags}/>
                }
            </div>
            {
                loaded && Iveno.Checker.isEmpty(items) ?
                    <Alert message="Изображения не найдены" type="error"/> :
                    <div className="imagesGrid">
                        {
                            _.map(items, (item, idx) => {
                                if (loading) {
                                    return <Skeleton.Image active key={idx}/>
                                }
                                return (
                                    <EsImage key={idx}
                                             src={item[mode === "gosrf" ? "preview" : "small"]}
                                             className="thumbnail"
                                             withPreview={false}
                                             onClick={() => selectImage(item)}
                                    />
                                )
                            })
                        }
                    </div>
            }
            <div className="paginationWrapper">
                <Pagination itemRender={itemRender}
                            total={count}
                            pageSize={limit}
                            hideOnSinglePage
                            showSizeChanger={false}
                            showTotal={() => renderTotalLabel()}
                            current={currentPage}
                            onChange={(page) => {
                                load(page, str)
                            }}
                />
            </div>
        </GalleryStyle>
    )
}