import styled from "styled-components";
import _ from "underscore";
import {useEffect, useState} from "react";
import {Select} from "antd";
import Net from "../../Libs/Net";
import {EsLinkSelect} from "../../Libs/EsLib/EsLinkSelect/EsLinkSelect";
import Session from "../../Config/Session";

const CategorySelectStyle = styled.div`

`

export const CategorySelect = function ({value, onChange, disabled, allowClear, style, linked, filtered}) {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        setLoading(true);
        Net.post("getCategories", {})
            .then(res => {
                setLoading(false);
                let _items = [];
                _.each(res, item => {
                    if (filtered) {
                        if (Session.getCategories().indexOf(item.id) !== -1) {
                            _items.push(item);
                        }
                    } else {
                        _items.push(item);
                    }
                });
                setItems(_items);
                // setItems(res);
            })
            .catch(() => {
            })
    }

    const options = _.map(items, item => {
        return {
            label: item["categoryName"],
            value: item.id
        }
    })

    if (linked) {
        return (
            <EsLinkSelect value={value}
                          disabled={disabled}
                          loading={loading}
                          onChange={onChange}
                          options={options}
                          allowClear={allowClear}
                          style={style}
                          placeholder="Выберите категорию"
                          showSearch
                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            />
        )
    }

    return (
        <Select value={value}
                disabled={disabled}
                loading={loading}
                onChange={onChange}
                options={options}
                allowClear={allowClear}
                style={style}
                placeholder="Выберите категорию"
                showSearch
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        />
    )
}