import styled from 'styled-components';
import { Form } from 'antd';
import { CategorySelect } from '../../Components/CategorySelect/CategorySelect';

const ArticleDataEditorStyle = styled.div``;

export const DataEditor = function ({ controller }) {
	return (
		<ArticleDataEditorStyle>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'baseline'
				}}
			>
				<Form.Item {...controller.getStatus('categoryID')}>
					<CategorySelect
						value={controller.getData('categoryID', null)}
						onChange={(value) => controller.setData('categoryID', value)}
						placeholder="Выберите категорию"
						linked
						style={{ width: '250px' }}
					/>
				</Form.Item>
			</div>
		</ArticleDataEditorStyle>
	);
};
