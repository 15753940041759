import styled from "styled-components";
import {Select} from "antd";

const EsLinkSelectStyle = styled.div`
    .ant-select-selector {
        background-color: var(--color-background) !important;
    }
    .ant-select-selection-item {
        color: var(--color-text);
    }
    .ant-select-arrow {
        display: none;
    }
`

export const EsLinkSelect = function (props) {

    return (
        <EsLinkSelectStyle>
            <Select bordered={false} {...props} />
        </EsLinkSelectStyle>
    )
}