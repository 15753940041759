import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import Iveno from '../../Libs/Iveno/Iveno';
import { useEffect, useState } from 'react';
import { WorkDayButton } from './HeaderItems/WorkDayButton';
import Session from '../../Config/Session';

const MainHeaderStyle = styled.div`
	.ant-layout-header {
		padding: 0 20px 0 0;
		background-color: var(--color-dark-background) !important;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-between;

		h3 {
			font-family: 'Geologica', sans-serif !important;
			font-weight: 600;
			margin-bottom: 0;
			color: #ffffff;
		}
	}
`;

const eventID = '2aae26a6-c87a-49e7-a82d-cb7f7e0d4596';

export const updateCaption = (caption) => {
	Iveno.Bus.emit(eventID, caption);
};

export const MainHeader = function () {
	const defaultCaption = Session.getLevel() === 4 ? 'РР Пиар' : 'Панель управления';

	const [caption, setCaption] = useState(defaultCaption);

	useEffect(() => {
		const scopeID = Iveno.Crypt.random();
		Iveno.Bus.on(
			eventID,
			(cap) => {
				if (Iveno.Checker.isEmpty(cap)) {
					setCaption(defaultCaption);
				} else {
					setCaption(cap);
				}
			},
			scopeID
		);
		return () => {
			Iveno.Bus.offScope(scopeID);
		};
	}, []);

	return (
		<MainHeaderStyle>
			<Layout.Header>
				<Typography.Title level={3}>{caption}</Typography.Title>
				{Session.getLevel() !== 4 && <WorkDayButton />}
			</Layout.Header>
		</MainHeaderStyle>
	);
};
