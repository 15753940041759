import Net from "../../Net";

export class EsTextEditUploadAdapter {

    loader;

    constructor(loader) {
        this.loader = loader;
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }

    onUploadProgress(progress) {
        this.loader.uploadTotal = progress.total;
        this.loader.uploaded = progress.loaded;
    }

    upload() {
        return this.loader.file.then(file => {
            return new Promise((resolve, reject) => {
                Net.post("saveToMedia", {file: file}, this.onUploadProgress)
                    .then(response => {
                        resolve({
                            default: response.original
                        })
                    })
                    .catch(err => {
                        reject(err);
                    })
            });
        })
    }

    abort() {
        return new Promise((resolve, reject) => {

        });
    }

}