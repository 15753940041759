import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { TextEdit } from './TextEdit/TextEdit';
import { DataEditor } from './DataEditor';
import { ImageEditor } from './ImageEditor/ImageEditor';
import { useUpdate } from 'ahooks';
import { ArticleController } from '../SimpleArticleEdit/ArticleController';
import Config, { RepositoryKeys } from '../../Config/Config';
import { SendButton } from './SendButton/SendButton';
import { isValidEmbedUrlStructure } from './ValidEmbedUrl';
import Net from '../../Libs/Net';
import { isDesktop } from 'react-device-detect';
import { Page404 } from './Page404/Page404';
import { ReactComponent as DoneOutline } from './Page404/Assets/done-outline.svg';
import Iveno from '../../Libs/Iveno/Iveno';
import './editor.css';

const SimpleEditorStyle = styled.div`
	width: 100%;
	max-width: 900px;
	min-height: 97vh;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	.section {
		.top-block {
			padding: 0 16px;
		}
		.titleEditor {
			font-size: 28px;
			font-family: 'Geologica', sans-serif !important;
			font-weight: 600;
			background-color: var(--color-background);

			.ant-input {
				padding-left: 0;
				line-height: 1.15;
			}
		}
	}
`;

const DoneWrap = styled.div`
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	background: #2780e8;
	opacity: 0.8;
	z-index: 97;
	position: absolute;
`;

const DoneIcon = styled(DoneOutline)`
	position: absolute;
	width: 100px;
	height: 100px;
	z-index: 980;
	left: 50%;
	top: 50%;
	display: block;
	transform: translate(-50%, -50%);
	z-index: 98;
	background-size: cover;
`;

const TelegramEditor = function () {
	const [isHolding, setIsHolding] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const update = useUpdate();

	const controller = useMemo(() => {
		const c = new ArticleController();
		c.addObserver(update);
		return c;
	}, [update]);

	const { id, token } = isValidEmbedUrlStructure(true) || {};

	const RedirectToHome = () => (window.location.href = 'https://gosrf.ru/');

	useEffect(() => {
		load();

		document.body.classList.add('tg-edit');

		return () => {
			document.body.classList.remove('tg-edit');
			controller.updateView();
			controller.clear();
			Config.setToken(null, false);
		};
	}, []);

	const load = () => {
		localStorage.setItem(RepositoryKeys.token, JSON.stringify(token));

		Net.post('getAuthors').catch(() => {
			Config.setToken(null, false);
			RedirectToHome();
		});
		controller
			.get(id)
			.then(() => {})
			.catch(() => {
				RedirectToHome();
			});
	};

	const onOk = () => {
		controller
			.savePromise()
			.then(() => {
				controller.publish().then(() => {
					window.close();
				});
			})
			.catch((err) => {
				Iveno.UI.notification.error(err);
			});
	};

	return (
		<>
			{isDesktop ? (
				<Page404 />
			) : (
				<Spin spinning={controller.loading} size="large">
					<Form
						layout="vertical"
						style={{ width: '100%', padding: '1.5vh' }}
						className={`tg-form-edit ${isHolding ? 'fill-up' : ''}`}
					>
						<SimpleEditorStyle>
							<Form.Item {...controller.getStatus('image')} className="imageField">
								<ImageEditor controller={controller} />
							</Form.Item>

							{controller?.fields?.id && (
								<div className="section">
									<div className="top-block">
										<Form.Item {...controller.getStatus('title')}>
											<Input.TextArea
												style={{ background: '#fff' }}
												value={controller.getData('title')}
												onChange={(e) => controller.setData('title', e.target.value || null)}
												placeholder="Заголовок"
												className="titleEditor"
												allowClear
												bordered={false}
												autoSize={{
													minRows: 1
												}}
											/>
										</Form.Item>
										<DataEditor controller={controller} />
									</div>
									<Form.Item {...controller.getStatus('bodyText')}>
										<TextEdit
											value={controller.getData('bodyText')}
											onChange={(val) => controller.setData('bodyText', val)}
										/>
									</Form.Item>
								</div>
							)}
						</SimpleEditorStyle>
						<SendButton
							onClick={onOk}
							isHolding={isHolding}
							setIsHolding={setIsHolding}
							isDone={isDone}
							setIsDone={setIsDone}
						/>
						{isDone && (
							<DoneWrap>
								<DoneIcon />
							</DoneWrap>
						)}
					</Form>
				</Spin>
			)}
		</>
	);
};

export default TelegramEditor;
