import styled from "styled-components";
import _ from "underscore";
import {useState} from "react";
import {useDeepCompareEffect} from "ahooks";
import Iveno from "../../Iveno/Iveno";
import {Image, Spin} from "antd";
import placeholder from "../../../Assets/Components/placeholder.svg"

const EsImageStyle = styled.div`

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.bordered {
        border-radius: 6px;
        border: 1px solid var(--color-gray);
    }

`

export const EsImage = function ({src, fallback, alt, width, height, bordered, className, type, withPreview, onClick}) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [preview, setPreview] = useState(null);

    useDeepCompareEffect(() => {
        setLoading(true);
        setError(false);
        preload().then(onLoad).catch(onError);
        setPreview(null);
    }, [src]);

    const getPlaceholder = () => {
        return fallback || placeholder;
    }

    const getSrc = () => {
        if (Iveno.Checker.isEmpty(src) || error) {
            return getPlaceholder();
        }
        if (_.isObject(src)) {
            if (_.has(src, "links")) {
                if (_.has(src.links, type || "preview")) {
                    return src.links[type || "preview"]['src'];
                }
            }
            if (_.has(src, type || "preview")) {
                return src[type || "preview"];
            }
            if (src instanceof File) {
                if (_.first(src.type.split("/")) === "image") {
                    if (preview === null) {
                        setPreview(window.URL.createObjectURL(src));
                        return getPlaceholder();
                    } else {
                        return preview;
                    }
                }
            }
        }
        return src;
    }

    const preload = () => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            const image = document.createElement('img');
            image.onload = resolve;
            image.onerror = reject;
            image.src = getSrc();
            image.remove();
        });
    }

    const onLoad = () => {
        setLoading(false);
        setError(false);
    }

    const onError = () => {
        setLoading(false);
        setError(true);
    }

    let classes = ["Image"]
    if (!Iveno.Checker.isEmpty(className)) {
        classes.push(className);
    }
    if (bordered) {
        classes.push("bordered");
    }

    const getWidth = () => {
        if (Iveno.Checker.isEmpty(width)) {
            return "100%";
        }
        return width;
    }

    const getHeight = () => {
        if (Iveno.Checker.isEmpty(height)) {
            return "100%";
        }
        return height;
    }

    return (
        <EsImageStyle className={classes.join(" ")} style={{width: getWidth(), height: getHeight()}}>
            {
                loading ?
                    <Spin spinning>
                        <Image loading="lazy"
                               preview={withPreview}
                               src={loading ? placeholder : getSrc()}
                               alt={alt || "..."}
                               style={{
                                   width: width || "100%",
                                   height: height || "100%"
                               }}
                        />
                    </Spin> :
                    <Image loading="lazy"
                           preview={withPreview}
                           onClick={onClick}
                           src={loading ? placeholder : getSrc()}
                           alt={alt || "..."}
                           style={{
                               width: width || "100%",
                               height: height || "100%"
                           }}
                    />
            }
        </EsImageStyle>
    )
}