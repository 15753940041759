import styled from "styled-components";
import _ from "underscore";
import {useEffect, useState} from "react";
import {Modal} from "antd";
import Iveno from "../../../Libs/Iveno/Iveno";
import {ModeSelector} from "./ModeSelector";
import {Gallery} from "./Gallery";

const ImageGalleryDialogStyle = styled.div`
    display: flex;
    align-items: flex-start;
`

const eventID = "d08383ac-05f7-487b-beb7-81dda1fa1c79";

export const openImageGalleryDialog = () => {
    Iveno.Bus.emit(eventID);
}

export const ImageGalleryDialog = function ({callback, controller}) {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("gosrf");

    useEffect(() => {
        const scopeID = Iveno.Crypt.random();
        Iveno.Bus.on(eventID, () => {
            setMode("gosrf");
            setLoading(false);
            setOpen(true);
        }, scopeID);
        return () => {
            Iveno.Bus.offScope(scopeID);
        }
    }, []);

    const onCancel = () => {
        setOpen(false);
    }

    const onOk = () => {
        if (_.isFunction(callback)) {
            callback();
        }
        setOpen(false);
    }

    return (
        <Modal title="Выбор изображения"
               open={open}
               cancelButtonProps={{type: "link", disabled: loading}}
               onCancel={onCancel}
               centered
               width={1000}
               destroyOnClose
               confirmLoading={loading}
               footer={null}
               styles={{
                   body: {padding: "0 10px 10px 0"}
               }}
               onOk={onOk}>
            <ImageGalleryDialogStyle>
                <ModeSelector mode={mode} onChange={setMode}/>
                <Gallery mode={mode} controller={controller} onSelect={() => setOpen(false)}/>
            </ImageGalleryDialogStyle>
        </Modal>
    )
}