import styled from 'styled-components';
import { ReactComponent as Pushkin } from './Assets/pushkin.svg';
import { ReactComponent as Inscription } from './Assets/inscription.svg';

const Wrapper = styled('div')`
	background: #2780e8;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MainBlock = styled('div')`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 300px;

	@media (min-width: 500px) and (max-width: 700px) {
		width: 250px;
		height: 250px;
	}
	@media (max-width: 499px) {
		width: 200px;
		height: 200px;
	}
`;

const InscriptionStyle = styled(Inscription)`
	position: absolute;
	top: -95%;
	left: 45%;
	width: 100%;

	@media (max-width: 700px) {
		top: -110%;
		left: 45%;
	}
`;

export const Page404 = () => (
	<Wrapper>
		<MainBlock>
			<InscriptionStyle />
			<Pushkin />
		</MainBlock>
	</Wrapper>
);
