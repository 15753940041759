import {validate} from "uuid";
import _ from "underscore";

const IvenoChecker = (function () {

    return {
        isEmail: (val) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(val).toLowerCase());
        },
        isUUID: (val) => {
            return validate(val);
        },
        isUrl: (val) => {
            const re = /^(http|https):\/\/[^ "]+$/;
            return re.test(String(val).toLowerCase());
        },
        isEmpty: (val) => {
            if (val === undefined) {
                return true;
            }
            if (val === null) {
                return true;
            }
            if (_.isNaN(val)) {
                return true;
            }
            if (_.isString(val)) {
                if (val.trim() === "") {
                    return true;
                }
            }
            if (_.isNumber(val)) {
                if (val === 0) {
                    return true;
                }
            }
            if (_.isArray(val)) {
                if (_.isEmpty(val)) {
                    return true;
                }
            }
            return false;
        },
        isMoreThan: (v1, v2) => {
            if (!_.isNumber(v1) || !_.isNumber(v2)) {
                return false;
            }
            return v1 * 1 > v2 * 1;
        },
        isPositive: (v) => {
            if (!_.isNumber(v)) {
                return false;
            }
            return v * 1 > 0;
        },
        isEqual: (v1, v2) => {
            let j1 = JSON.stringify(v1);
            let j2 = JSON.stringify(v2);
            return j1 === j2;
        },
        inArray: (val, arr) => {
            return _.indexOf(arr, val) !== -1;
        }
    }

})()

export default IvenoChecker;