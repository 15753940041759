import styled from "styled-components";
import {useEffect, useMemo} from "react";
import {App, Button, Form, Input, Typography} from "antd";
import Controller from "../../Libs/Controller";
import Rule from "../../Libs/Rule";
import {useUpdate} from "ahooks";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {EsActionsAlignment, EsActionsContainer} from "../../Libs/EsLib/EsActionsContainer/EsActionsContainer";
import Utils from "../../Libs/Utils";
import Config from "../../Config/Config";
import Iveno from "../../Libs/Iveno/Iveno";

const AuthPageStyle = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .authWindow {
        border-radius: 25px;
        background-color: #ffffff;
        padding: 30px 40px;
        box-shadow: rgba(255, 255, 255, 0.1) 0 1px 1px 0 inset, rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        min-width: 600px;

        h1 {
            font-family: 'Geologica', sans-serif !important;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }
`

const AuthPage = function () {

    const app = App.useApp();

    useEffect(() => {
        Config.setApp(app);
        Iveno.UI.setApp(app);
    }, []);

    const update = useUpdate();
    const controller = useMemo(() => {
        const c = new Controller(null, "auth");
        c.setStructure({
            userLogin: "",
            userPassword: ""
        })
        c.clear();
        c.addObserver(update);
        c.addRule("userLogin", Rule.isEmail, "Электронный адрес введен не корректно")
        c.addRule("userPassword", Rule.notEmpty, "Пароль введен не корректно")
        return c;
    }, [update]);

    const login = () => {
        controller.savePromise()
            .then(() => {
                Config.setToken(controller.getData("token"))
            })
            .catch(() => {})
    }

    return (
        <AuthPageStyle>
            <div className="authWindow">
                <Typography.Title style={{textAlign: "center"}}>Вход в систему</Typography.Title>
                <Form layout="vertical">
                    <Form.Item {...controller.getStatus("userLogin")}>
                        <Input type="email"
                               value={controller.getData("userLogin", null)}
                               onChange={e => controller.setData("userLogin", e.target.value)}
                               size="large"
                               placeholder="Электронный адрес"
                               disabled={controller.loading}
                               prefix={<UserOutlined/>}
                               autoFocus
                        />
                    </Form.Item>
                    <Form.Item {...controller.getStatus("userPassword")}>
                        <Input.Password value={controller.getData("userPassword", null)}
                                        onChange={e => controller.setData("userPassword", e.target.value)}
                                        size="large"
                                        placeholder="Пароль"
                                        disabled={controller.loading}
                                        prefix={<LockOutlined/>}
                        />
                    </Form.Item>
                    <EsActionsContainer alignment={EsActionsAlignment.CENTER}>
                        <Button type="primary"
                                size="large"
                                disabled={Utils.isEmpty(controller.getData("userLogin")) || Utils.isEmpty(controller.getData("userPassword"))}
                                loading={controller.loading}
                                onClick={login}
                        >
                            Войти в систему
                        </Button>
                    </EsActionsContainer>
                </Form>
            </div>
        </AuthPageStyle>
    )
}

export default AuthPage;