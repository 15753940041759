import IvenoChecker from "./IvenoChecker";
import _ from "underscore";

const IvenoRule = (function () {
	return {
		uuid: (val) => IvenoChecker.isUUID(val),
		numeric: (val) => _.isNumber(val),
		empty: (val) => IvenoChecker.isEmpty(val),
		notEmpty: (val) => !IvenoChecker.isEmpty(val),
		minLength: (len) => {
			let f = function (val) {
				return _.size(val) >= len;
			}
			f.bind(len);
			return f;
		},
		isEqual: (v2) => {
			let f = function (val) {
				return val === v2;
			}
			console.log(v2);
			f.bind(v2);
			return f;
		},
		isEmail: (val) => IvenoChecker.isEmail(val)
	}
})()

export default IvenoRule;