import Controller from '../../Libs/Controller';
import Iveno from '../../Libs/Iveno/Iveno';
import Net from '../../Libs/Net';

export class ArticleController extends Controller {
	generation = false;

	constructor() {
		super('getArticle', 'editArticle', 'deleteArticle');
		this.addRule('image', Iveno.Rule.notEmpty, 'Не выбрано основное изображение');
		this.addRule('title', Iveno.Rule.notEmpty, 'Заголовок должен быть заполнен');
		this.addRule('bodyText', Iveno.Rule.notEmpty, 'Текст статьи не может быть пустым');
		this.addRule('categoryID', Iveno.Rule.notEmpty, 'Не выбрана категория');
		// this.addRule("columns", Iveno.Rule.notEmpty, "Не выбрана размерность");
	}

	get(id) {
		return super.get({ id: id });
	}

	readonly() {
		return this.loading || Iveno.Checker.isEmpty(this.getData('moderatorID', null));
	}

	assignArticleToMe() {
		return new Promise((resolve) => {
			Net.post('assignArticleToMe', { id: this.getData('id', null) })
				.then(() => {
					this.get(this.getData('id', null))
						.then(() => {
							resolve();
						})
						.catch(() => {
							resolve();
						});
				})
				.catch(() => {
					resolve();
				});
		});
	}

	isNew() {
		return Iveno.Checker.isEmpty(this.getData('id', null));
	}

	publish() {
		return new Promise((resolve) => {
			Net.post('publish', {
				id: this.getData('id', null),
				pubDate: this.getData('pubDate', null),
				exportParams: this.getData('exportParams', {})
			})
				.then(() => {
					this.updateView();
					Iveno.UI.notification.success('Материал опубликована');
					resolve();
				})
				.catch(() => {
					this.updateView();
					resolve();
				});
		});
	}

	unpublish() {
		this.loading = true;
		this.updateView();
		Net.post('unpublish', {
			id: this.getData('id', null),
			pubDate: this.getData('pubDate', null),
			exportParams: this.getData('exportParams', {})
		})
			.then(() => {
				this.get(this.getData('id'))
					.then(() => {
						Iveno.UI.notification.success('Материал снят с публикации');
					})
					.catch(() => {});
			})
			.catch(() => {
				this.updateView();
			});
	}

	isPublished() {
		return this.getData('status', null) === 5;
	}

	fetchStatus() {
		return new Promise((resolve) => {
			Net.post('getArticleStatus', { id: this.getData('id') })
				.then((status) => {
					resolve(status);
				})
				.then(() => {
					resolve(0);
				});
		});
	}

	regenerate(task) {
		return new Promise((resolve) => {
			this.savePromise(true)
				.then(() => {
					Net.post('reGenerate', {
						id: this.getData('id', null),
						task: task
					}).then(() => {
						const interval = setInterval(() => {
							this.fetchStatus().then((status) => {
								if (status === 4) {
									clearInterval(interval);
									this.get(this.getData('id', null)).then(() => {
										resolve();
									});
								}
							});
						}, 1000);
					});
				})
				.catch(() => {});
		});
	}

	setGeneration(val) {
		this.generation = val;
		this.updateView();
	}

	getSourceArticle() {
		return new Promise((resolve) => {
			Net.post('getSourceArticle', { id: this.getData('id', null) })
				.then((res) => {
					resolve(res);
				})
				.catch(() => {
					Iveno.UI.errorDialog('Ошибка загрузки исходного материала');
				});
		});
	}

	getArticleSources() {
		return new Promise((resolve) => {
			Net.post('getArticleSources', { id: this.getData('id', null) })
				.then((res) => {
					resolve(res);
				})
				.catch(() => {
					Iveno.UI.errorDialog('Ошибка загрузки исходных материалов');
				});
		});
	}

	getArticleMetadata() {
		return new Promise((resolve) => {
			if (this.isNew()) {
				resolve([]);
				return;
			}
			Net.post('getArticleMetadata', { articleID: this.getData('id', null) })
				.then((res) => {
					resolve(res);
				})
				.catch(() => {
					Iveno.UI.errorDialog('Ошибка загрузки метаданных');
				});
		});
	}

	approve() {
		this.loading = true;
		this.updateView();
		return new Promise((resolve) => {
			this.savePromise(true)
				.then(() => {
					Net.post('approveArticle', { id: this.getData('id', null) })
						.then(() => {
							this.loading = false;
							this.updateView();
							Iveno.UI.notification.success('Материал одобрена');
							resolve();
						})
						.catch(() => {
							this.loading = false;
							this.updateView();
							Iveno.UI.errorDialog('Ошибка одобрения материала');
						});
				})
				.catch(() => {});
		});
	}
}
