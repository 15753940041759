const IvenoRepository = (function () {
	let data = {};

	return {
		put: (key, value) => {
			data[key] = value;
		},
		get: (key) => {
			return data[key];
		},
		has: (key) => {
			let value = data[key];
			return value !== undefined;
		},
		remove: (key) => {
			delete data[key];
		},
		clear: () => {
			data = {}
		},
		store: (key, value) => {
			window.localStorage.setItem(key, JSON.stringify(value));
		},
		restore: (key) => {
			return JSON.parse(window.localStorage.getItem(key));
		},
		removeStore: (key) => {
			window.localStorage.removeItem(key);
		}
	}
})()

export default IvenoRepository;