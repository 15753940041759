import {sha256} from "js-sha256";
import {v4 as uuid_v4} from 'uuid'
import md5 from 'md5';

const IvenoCrypt = (function () {

	return {
		encrypt: (str) => {
			let res = str;
			for (let i = 0; i < 10; i++) {
				res = sha256(res);
			}
			return res;
		},
		random: () => sha256(uuid_v4()),
		uuid: () => uuid_v4(),
		md5: (val) => md5(val)
	}
})()

export default IvenoCrypt;