import styled from "styled-components";
import {Link} from "react-router-dom";

const LogoStyle = styled.div`
    svg {
        width: 58px;
        height: 58px;
    }
`

export const Logo = function () {

    return (
        <Link to={"/"}>
            <LogoStyle className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10000 9992.5">
                    <g>
                        <path fill="#ffffff"
                              d="M2653.18,9942.5c-176.65,0-353.18-17.79-524.68-52.89-167.12-34.2-331.5-85.22-488.58-151.66-154.24-65.24-303.01-145.99-442.17-240-137.85-93.13-267.49-200.09-385.3-317.9-117.81-117.81-224.77-247.45-317.9-385.3-94.02-139.16-174.77-287.93-240-442.17-66.44-157.08-117.47-321.46-151.66-488.58-35.09-171.5-52.89-348.03-52.89-524.68V2653.18c0-176.65,17.79-353.19,52.89-524.69,34.2-167.12,85.22-331.5,151.66-488.57,65.24-154.24,145.99-303.01,240-442.17,93.13-137.85,200.09-267.49,317.9-385.3,117.81-117.81,247.45-224.77,385.3-317.9,139.16-94.02,287.93-174.77,442.17-240,157.08-66.44,321.46-117.47,488.58-151.66,171.5-35.09,348.03-52.89,524.68-52.89H7346.82c176.66,0,353.18,17.79,524.68,52.89,167.12,34.2,331.5,85.22,488.58,151.66,154.24,65.24,303,145.99,442.17,240,137.85,93.13,267.49,200.09,385.3,317.9,117.81,117.81,224.77,247.44,317.9,385.3,94.02,139.16,174.77,287.93,240,442.17,66.44,157.08,117.47,321.46,151.66,488.57,35.09,171.5,52.89,348.03,52.89,524.69V7339.32c0,176.65-17.79,353.18-52.89,524.68-34.2,167.12-85.22,331.5-151.66,488.58-65.24,154.24-145.99,303.01-240,442.17-93.13,137.85-200.09,267.49-317.9,385.3-117.81,117.81-247.45,224.77-385.3,317.9-139.16,94.02-287.93,174.77-442.17,240-157.08,66.44-321.46,117.46-488.58,151.66-171.5,35.09-348.03,52.89-524.68,52.89H2653.18Z"/>
                    </g>
                    <path fill="#00254b" stroke="#ffffff"
                          d="M4424.98,1841.74c1114.64,0,1600.64,649.75,1600.64,1325.94,0,591.66-422.6,1304.81-1574.22,1304.81h-776.55v1067.09h-1415.74V1841.74h2165.86Zm-787.11,1774.95h311.69c169.05,0,649.76-10.54,649.76-459.56,0-216.62-116.23-459.61-655.05-459.61h-306.4v919.17Z"/>
                    <path fill="#dd2c34" stroke="#ffffff"
                          d="M6581.73,4929.29c1114.64,0,1600.64,649.73,1600.64,1325.91,0,591.67-422.6,1304.86-1574.22,1304.86h-776.55v1067.06h-1415.74v-3697.83h2165.86Zm-787.11,1774.98h311.69c169.05,0,649.76-10.59,649.76-459.56,0-216.62-116.23-459.65-655.05-459.65h-306.4v919.22Z"/>
                </svg>
            </LogoStyle>
        </Link>
    )
}