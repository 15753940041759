import _ from "underscore";

const IvenoLocale = (function () {

    let resources = {}

    return {
        addResource: (resource) => {
            resources = _.extend(resources, resource);
        },
        getResources: () => resources
    }
})()

export default IvenoLocale;